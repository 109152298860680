<form *ngIf="kycForm" [formGroup]="kycForm" class="flex flex-col mx-auto">
	<div class="flex flex-col bg-white">
		<h2 class="text-xl mb-common" translate="kyc.ubo-list.title"></h2>
		<div class="mb-common" translate="kyc.ubo-list.pre"></div>
		<app-ubo-list [ubosLoading]="ubosLoading" [ubos]="ubos" (onAddClick)="addUBOModalVisible = true" (onRemoveClick)="deleteUBO($event)"></app-ubo-list>
	</div>
	<ng-content></ng-content>
	<div class="flex flex-col bg-white py-common">
		<h1 class="text-xl mb-common" translate="kyc.pep.title"></h1>
		<p class="mb-common" translate="kyc.pep.pre"></p>
		<div class="flex items-center gap-common mb-common">
			<app-radio-select-item [value]="false" formControlName="isAnyOwnerPEP" [title]="'pep.no'"></app-radio-select-item>
			<app-radio-select-item [value]="true" formControlName="isAnyOwnerPEP" [title]="'pep.yes'"></app-radio-select-item>
		</div>
		<div *ngIf="isAnyOwnerPEP.value" [@openClose]>
			<h2 class="text-base my-common" translate="kyc.pep.owners.title" *ngIf="showUbosAndPep"></h2>
			<app-notification *ngIf="(ubos.dirty || ubos.touched) && ubos.errors?.atLeastOnePEP" type="error">
				{{
				'kyc.ubo.errors.at-least-one.pep' | translate }}
			</app-notification>
			<ng-container formArrayName="ubos" *ngFor="let ubo of ubos.controls; let i = index">
				<div class="ml-1" [formGroupName]="i">
					<app-checkbox formControlName="is_pep" *ngIf="showUbosAndPep">
						<span text>{{ ubo.value.first_name + ' ' + ubo.value.last_name }}</span>
					</app-checkbox>
					<div *ngIf="ubo.value.is_pep" class="flex flex-wrap p-3">
						<div class="w-full">
							<app-select
								[label]="'kyc.pep.owner.pep-how' | translate"
								[placeholder]="'select.placeholder' | translate"
								[options]="PEPTypeOptions"
								name="pep_type"
								formControlName="pep_type"></app-select>
						</div>
					</div>
				</div>
			</ng-container>
		</div>
	</div>

	<div class="flex flex-col bg-white py-common">
		<h1 class="text-xl mb-common" translate="kyc.hrc.title"></h1>
		<div class="flex items-center gap-common mb-common">
			<app-radio-select-item [value]="false" formControlName="businessOutsideEU" [title]="'kyc.outside-eu.no'"></app-radio-select-item>
			<app-radio-select-item [value]="true" formControlName="businessOutsideEU" [title]="'kyc.outside-eu.yes'"></app-radio-select-item>
		</div>
		<div *ngIf="businessOutsideEU.value" [@openClose]>
			<h2 class="text-base mt-common mb-common" translate="kyc.hrc.choose-countries.title"></h2>
			<app-notification *ngIf="(highRiskCountries.dirty || highRiskCountries.touched) && highRiskCountries.errors?.atLeastOneCountry" type="error">
				{{ 'kyc.ubo.errors.at-least-one.country' | translate }}
			</app-notification>
			<ng-container formArrayName="highRiskCountries" *ngFor="let country of highRiskCountries.controls; let i = index">
				<div class="ml-1" [formGroupName]="i">
					<app-checkbox formControlName="checked">
						<span text>{{ country.value.name }}</span>
					</app-checkbox>
				</div>
			</ng-container>
			<div class="ml-1 border-t py-common border-light-grey">
				<app-checkbox formControlName="noCountries">
					<span text>{{ 'kyc.hrc.non-of-above' | translate }}</span>
				</app-checkbox>
			</div>
		</div>
	</div>
	<div class="flex flex-col items-center w-full pt-6" *ngIf="showSaveButton">
		<app-button (action)="save()" [loading]="loading" class="mb-common">{{ 'kyc.save' | translate }}</app-button>
	</div>
</form>

<add-ubo-modal *ngIf="addUBOModalVisible" [visible]="addUBOModalVisible" (close)="addUBOModalVisible = false" (add)="addUBO($event)"></add-ubo-modal>
