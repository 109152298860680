import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PrefixTranslationKeysService {
  
  private prefix = null;
  private partnerPrefix = null;

  constructor() {}

  getPrefix(){
    return this.prefix;
  }

  setPrefix(prefix: string){
    this.prefix = prefix;
  }

  getPartnerPrefix() {
    return this.partnerPrefix;
  }
  
  setPartnerPrefix(prefix: string){
    this.partnerPrefix = prefix;
  }
}
