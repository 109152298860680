import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { frodaVariables } from './assets/froda-theme';
import { swedbankPayVariables } from './assets/swedbank-pay-theme';
import { yabieVariables } from './assets/yabie-theme';
import { lunarVariables } from './assets/lunar-theme';
import { kontistVariables } from './assets/kontist-theme';
import { vismaVariables } from './assets/visma-theme';
import { billyVariables } from './assets/billy-theme';

@Injectable()
export class TailwindThemeService {
	constructor(@Inject(DOCUMENT) private readonly document: Document) {}

	loadConfig() {
		const theme = this.getTheme();
		this.updateThemeVariables(theme);
	}

	getTheme() {
		const path = this.document.location.pathname;
		switch (true) {
			case path.includes('swedbank'):
				return swedbankPayVariables;
			case path.includes('yabie'):
				return yabieVariables;
			case path.includes('lunar'):
				return lunarVariables;
			case path.includes('kontist'):
				return kontistVariables;
			case path.includes('visma'):
				return vismaVariables;
			case path.includes('billy'):
				return billyVariables;
			default:
				return frodaVariables;
		}
	}

	getThemeByName(name: string) {
		let theme;
		switch (name) {
			case 'swedbank-pay':
				theme = swedbankPayVariables;
				break;
			case 'yabie':
				theme = yabieVariables;
				break;
			case 'lunar':
				theme = lunarVariables;
				break;
			case 'kontist':
				theme = kontistVariables;
				break;
			case 'billy':
				theme = billyVariables;
				break;
			case 'visma':
				theme = vismaVariables;
				break;
			default:
				theme = frodaVariables;
				break;
		}
		return theme;
	}

	updateThemeVariables(theme) {
		for (const [name, color] of Object.entries(theme)) {
			this.document.documentElement.style.setProperty(`--${name}`, color as string);
		}
	}
}
