import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { ActionTrackingHttpService } from './api/actionTracking.service';
import { AssentlyHttpService } from './api/assently.service';
import { ConsentsHttpService } from './api/consents.service';
import { ContractsHttpService } from './api/contracts.service';
import { CreditProductHttpService } from './api/creditProduct.service';
import { CreditsHttpService } from './api/credits.service';
import { CustomerSatisfactionHttpService } from './api/customerSatisfaction.service';
import { KycHttpService } from './api/kyc.service';
import { LoggingHttpService } from './api/logging.service';
import { OnboardingHttpService } from './api/onboarding.service';
import { OrganizationMockDataHttpService } from './api/organizationMockData.service';
import { OrganizationsHttpService } from './api/organizations.service';
import { PartnerBamboraHttpService } from './api/partnerBambora.service';
import { PartnerClientHttpService } from './api/partnerClient.service';
import { PartnerOnboardingHttpService } from './api/partnerOnboarding.service';
import { PaymentsHttpService } from './api/payments.service';
import { PricingHttpService } from './api/pricing.service';
import { PricingLegacyHttpService } from './api/pricingLegacy.service';
import { PurchaseFinancingHttpService } from './api/purchaseFinancing.service';
import { PurposesHttpService } from './api/purposes.service';
import { RepaymentHttpService } from './api/repayment.service';
import { ScrapingCallbacksHttpService } from './api/scrapingCallbacks.service';
import { SharedFilesHttpService } from './api/sharedFiles.service';
import { TrapetsHttpService } from './api/trapets.service';

@NgModule({
	imports: [],
	declarations: [],
	exports: [],
	providers: [],
})
export class ApiModule {
	public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
		return {
			ngModule: ApiModule,
			providers: [{ provide: Configuration, useFactory: configurationFactory }],
		};
	}

	constructor(@Optional() @SkipSelf() parentModule: ApiModule,
							@Optional() http: HttpClient) {
		if (parentModule) {
			throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
		}
		if (!http) {
			throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
				'See also https://github.com/angular/angular/issues/20575');
		}
	}
}
