import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
	selector: 'app-radio-select-item',
	templateUrl: './radio-select-item.component.html',
	styleUrls: ['./radio-select-item.component.scss'],
})
export class RadioSelectItemComponent implements OnInit, ControlValueAccessor {
	@Input() borderRadius?: string = 'rounded-box';
	@Input() checkedBorderColor?: string = 'border-button-color';
	@Input() checkedBgColor?: string = 'bg-button-color';
	@Input() borderColor?: string = 'border-light-grey';
	@Input() textColor?: string = 'text-almost-black';
	@Input() checkedTextColor?: string = 'text-almost-black';
	@Input() title?: string;
	@Input() padding?: string;
	@Input() description?: string;
	@Input() value: any;

	onTouched = () => {};
	onChanged = (_: any) => {};

	model: any;

	constructor(@Self() @Optional() public control: NgControl) {
		this.control.valueAccessor = this;
	}

	ngOnInit() {
		this.control.control.valueChanges.subscribe(value => {
			if (this.model === value) return;
			this.writeValue(value);
		});
	}

	writeValue(value: any): void {
		this.model = value;
	}

	registerOnChange(fn: any): void {
		this.onChanged = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	onChange() {
		this.model = this.value;
		this.onChanged(this.model);
	}

	hasErrors() {
		return (this.control.dirty || this.control.touched) && this.control.errors;
	}

	isChecked() {
		return JSON.stringify(this.model) === JSON.stringify(this.value);
	}

	customCheckedStyle(color: string) {
		// Dynamic generating class names doesn't work with TailwindCSS
		// https://tailwindcss.com/docs/content-configuration#dynamic-class-names
		// If the color name from some other theme doesn't match the color name in the default theme, new condition should be added
		if (color === 'border-button-color') {
			return 'checked:focus:border-button-color checked:hover:border-button-color checked:border-button-color';
		}
		return '';
	}
}
