<form *ngIf="hrcForm" [formGroup]="hrcForm">
	<div class="flex items-center gap-4">
		<app-radio-select-item
			[value]="false"
			formControlName="businessOutsideEU"
			[title]="'onboarding.application.kyc.outside-eu.no'"
			[borderRadius]="'rounded'"></app-radio-select-item>
		<app-radio-select-item
			[value]="true"
			formControlName="businessOutsideEU"
			[title]="'onboarding.application.kyc.outside-eu.yes'"
			[borderRadius]="'rounded'"></app-radio-select-item>
	</div>
	<div *ngIf="businessOutsideEU.value" class="overflow-hidden" [@openClose]>
		<h2 class="mt-6 mb-4 text-base" translate="onboarding.application.kyc.hrc.choose-countries.title"></h2>
		<app-notification *ngIf="(highRiskCountries.dirty || highRiskCountries.touched) && highRiskCountries.errors?.atLeastOneCountry" type="error">
			{{ 'onboarding.application.kyc.ubo.errors.at-least-one.country' | translate }}
		</app-notification>
		<ng-container formArrayName="highRiskCountries" *ngFor="let country of highRiskCountries.controls; let i = index">
			<div class="ml-1" [formGroupName]="i">
				<app-checkbox formControlName="checked">
					<span text>{{ country.value.name }}</span>
				</app-checkbox>
			</div>
		</ng-container>
		<div class="py-4 ml-1 border-t border-light-grey">
			<app-checkbox formControlName="noCountries">
				<span text>{{ 'onboarding.application.kyc.hrc.non-of-above' | translate }}</span>
			</app-checkbox>
		</div>
	</div>
</form>
