import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { AppRoutingModule, routedComponents } from './app-routing.module';
import { TailwindThemeModule } from './tailwind-theme-config/tailwind-theme.module';

import { Angulartics2Module } from 'angulartics2';
import * as Hammer from 'hammerjs';

import { AppComponent } from './app.component';

import { AppConfig } from './bootstrapping/appconfig';

import { HttpClient } from '@angular/common/http';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';

import { Configuration as ApiConfiguration, ApiModule } from './api';
import { Configuration as ScrapingApiConfiguration, ScrapingApiModule } from './api-scraping';
import { AuthService, JwtInterceptor, TokenInterceptor } from './shared/auth';
import { SharedModule } from './shared/shared.module';

import localeDa from '@angular/common/locales/da';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeFi from '@angular/common/locales/fi';
import localeNb from '@angular/common/locales/nb';
import localeSv from '@angular/common/locales/sv';

import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { DEFAULT_APP_LANGUAGE } from './app.contants';
import { WhiteLabelClientSettingsService } from './services';
import { ErrorHandlerService } from './services/error-handler.service';
import { FeatureFlagsService } from './services/feature-flags.service';
import { LanguageInitializerService } from './services/language-initializer.service';
import { FrodaMissingTranslationHandler } from './services/missing-translation.handler';
import { TranslateLoaderService } from './services/translate-loader.service';

const bootstrapper = environment.bootstrapper;
// making hammer config (3)
export class MyHammerConfig extends HammerGestureConfig {
	overrides = {
		swipe: { direction: Hammer.DIRECTION_ALL },
	} as any;
}

@NgModule({
	declarations: [AppComponent, ...routedComponents],
	exports: [],
	bootstrap: [AppComponent],
	imports: [
		AppRoutingModule,
		BrowserModule,
		HammerModule,
		BrowserAnimationsModule,
		ApiModule,
		ScrapingApiModule,
		CommonModule,
		SharedModule,
		TailwindThemeModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useClass: TranslateLoaderService,
				deps: [HttpClient],
			},
			missingTranslationHandler: {
				provide: MissingTranslationHandler,
				useClass: FrodaMissingTranslationHandler,
			},
		}),
		Angulartics2Module.forRoot({
			pageTracking: {
				basePath: '/$web-customer',
				excludedRoutes: [new RegExp(/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/)],
				clearIds: true,
				idsRegExp: new RegExp(/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/),
				clearQueryParams: true,
			},
		}),
		Sentry.TraceModule,
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: JwtInterceptor,
			multi: true,
		},
		{
			provide: AppConfig,
			useValue: bootstrapper.appConfig,
		},
		{ provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
		{
			provide: ApiConfiguration,
			useFactory: (appConfig: AppConfig, authService: AuthService) =>
				new ApiConfiguration({
					basePath: appConfig.apiBaseUrl,
					accessToken: authService.getToken(),
				}),
			deps: [AppConfig, AuthService],
			multi: false,
		},
		{
			provide: ScrapingApiConfiguration,
			useFactory: (appConfig: AppConfig) =>
				new ScrapingApiConfiguration({
					basePath: appConfig.scrapingBaseUrl,
				}),
			deps: [AppConfig],
			multi: false,
		},
		{
			provide: APP_INITIALIZER,
			useFactory: (featureFlagsService: FeatureFlagsService) => () => featureFlagsService.init(),
			deps: [FeatureFlagsService],
			multi: true,
		},
		//use APP_INITIALIZER to load partner settings based on url
		{
			provide: APP_INITIALIZER,
			useFactory: (whiteLabelClientSettingsService: WhiteLabelClientSettingsService) => () =>
				whiteLabelClientSettingsService.loadPartnerSettings(),
			deps: [WhiteLabelClientSettingsService],
			multi: true,
		},
		{
			provide: APP_INITIALIZER,
			useFactory: (languageInitializerService: LanguageInitializerService) => () =>
				languageInitializerService.initLanguage(),
			deps: [LanguageInitializerService],
			multi: true,
		},
		{
			provide: LOCALE_ID,
			deps: [TranslateService],
			useFactory: translate => {
				return translate.currentLang || DEFAULT_APP_LANGUAGE;
			},
		},
		{
			provide: ErrorHandler,
			useClass: ErrorHandlerService,
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		// use APP_INITIALIZER to force instantiate Tracing
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => {},
			deps: [Sentry.TraceService],
			multi: true,
		},
		...bootstrapper.providers,
		provideHttpClient(withInterceptorsFromDi()),
	],
})
export class AppModule {
	constructor() {
		registerLocaleData(localeSv, 'sv');
		registerLocaleData(localeDa, 'da');
		registerLocaleData(localeFi, 'fi');
		registerLocaleData(localeEn, 'en');
		registerLocaleData(localeNb, 'nb');
		registerLocaleData(localeDe, 'de');
	}
}
