<div
	class="flex w-full items-center border-2 cursor-pointer bg-white
  {{ hasErrors() ? 'border-system-red' : isChecked() ? checkedBorderColor : borderColor }}
  {{ borderRadius }}"
	[ngClass]="!!padding ? padding : 'p-5 pr-8 py-3.5'"
	(click)="onChange()">
	<label class="flex items-center cursor-pointer ">
		<div class="relative flex items-center">
			<input
				type="radio"
				[checked]="isChecked()"
				class="w-5 h-5 transition-colors duration-300 border-2 outline-none focus:ring-0 {{ checkedBorderColor }} {{ customCheckedStyle(checkedBorderColor) }} checked:bg-white checked:border-2 form-radio text-radio-500 checked:focus:bg-white checked:focus:border-2  checked:hover:bg-white checked:hover:border-2 hover:cursor-pointer"
				[value]="value"
				(change)="onChange()"
				(blur)="onTouched()" />
			<span *ngIf="isChecked()" class="w-2.5 h-2.5 absolute {{ checkedBgColor }} top-[5px] left-[5px] rounded-full"></span>
		</div>
		<span class="ml-2 leading-none text-almost-black">
			<div *ngIf="title || description" class="flex flex-col ml-2">
				<h3 *ngIf="title" class="text-base leading-6 {{textColor}}">
					{{ title | translate }}
				</h3>
				<span *ngIf="description" class="text-sm {{textColor}}">
					{{ description | translate }}
				</span>
			</div>
			<ng-content></ng-content>
		</span>
	</label>
</div>
