<app-modal-card [visible]="visible" (close)="close.emit()" [closable]="!removalInProgress">
	<h1 class="mb-6">{{ title }}</h1>
	<p class="mb-6" *ngIf="!removalInProgress">{{ text }}</p>
	<app-loader *ngIf="removalInProgress" class="flex justify-center mb-8"></app-loader>
	<div class="flex flex-col items-center sm:items-end">
		<app-button class="w-full mb-2 text-center" type="secondary" (action)="remove.emit()" [disabled]="removalInProgress">
			{{ removeBtnText }}
		</app-button>
	</div>
</app-modal-card>
