import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
	selector: 'app-select',
	templateUrl: './select.component.html',
	styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit, ControlValueAccessor {
	@Input() label: string;
	@Input() labelColor = 'text-almost-black';
	@Input() labelSize = 'text-base';
	@Input() options: object[];
	@Input() value: object;
	@Input() disabled = false;
	@Input() required = true;
	@Input() placeholder: string;

	onTouched = () => {};
	onChanged = (_: any) => {};

	constructor(@Self() @Optional() public control: NgControl) {
		this.control.valueAccessor = this;
	}

	ngOnInit() {}

	writeValue(value: any): void {
		this.value = value;
	}

	registerOnChange(fn: any): void {
		this.onChanged = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	onChange(value) {
		this.value = value;
		this.onChanged(this.value);
	}

	hasErrors() {
		return (this.control.dirty || this.control.touched) && this.control.errors;
	}
}
