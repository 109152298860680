import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { WithdrawalRequestDto } from 'app/api';
import { WithdrawalRequestStep } from 'app/models/withdrawalRequestStep';
import { getStepPosition, processStep } from 'app/services/withdrawal-request-functions';

const WITHDRAWAL_REQUEST_STEPS = [
	{
		title: 'home.wrstatus.request-submitted',
	},
	{
		title: 'home.wrstatus.credit-assessment',
		content: 'wrstatus.credit-assessment.pre',
	},
	{
		title: 'home.wrstatus.contracts.title',
		content: 'wrstatus.contracts.pre',
		showSignators: true,
	},
	{
		title: 'home.wrstatus.payout',
		content: 'wrstatus.payout.pre',
	},
];

@Component({
	selector: 'wr-status',
	templateUrl: './wr-status.component.html',
	styleUrls: ['./wr-status.component.scss'],
})
export class WrStatusComponent implements OnInit, OnChanges {
	@Input() pendingRequest: WithdrawalRequestDto;
	@Input() shouldSignContractAfterAssessment = false;
	@Input() withdrawalRequestSteps = WITHDRAWAL_REQUEST_STEPS;
	@Input() bgColor = 'bg-almost-black';
	@Input() signatoryTitleTextColor = 'text-almost-black';
	@Input() signatorySubTextColor = 'text-medium-grey';
	WithdrawalRequestStep = WithdrawalRequestStep;
	currentStepPosition: number;
	currentStep: WithdrawalRequestStep;

	constructor() {}

	ngOnInit(): void {}

	ngOnChanges(): void {
		if (this.pendingRequest) {
			this.currentStep = processStep(this.pendingRequest, this.shouldSignContractAfterAssessment);
			this.currentStepPosition = this.getStepPosition(this.currentStep);
		}
	}

	getStepPosition(step: WithdrawalRequestStep) {
		return getStepPosition(step, this.shouldSignContractAfterAssessment);
	}

	get waitingForContractsCreated(): boolean {
		return (
			!!this.pendingRequest &&
			this.currentStep === WithdrawalRequestStep.AwaitingContractsSigned &&
			this.pendingRequest.signatories.length === 0
		);
	}
}
