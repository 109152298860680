import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SentryService } from '@services';
import { smoothOpenClose } from 'app/animations/smooth.animations';
import { CARD_BIN_CHECKING } from 'app/app.contants';
import { AppConfig } from 'app/bootstrapping/appconfig';
import { CheckoutResponse } from 'app/models/checkoutResponse';
import { FeatureFlagsService } from 'app/services/feature-flags.service';
import { WhiteLabelClientSettingsService } from 'app/services/white-label-client-settings.service';
import { from } from 'rxjs';

declare const Frames;

type CkoElementType = 'card-number' | 'expiry-date' | 'cvv';
type CkoPaymentMethodType =
	| 'Visa'
	| 'Maestro'
	| 'Mastercard'
	| 'American Express'
	| 'Diners Club'
	| 'Discover'
	| 'JCB'
	| 'Mada';

@Component({
	selector: 'app-checkout-card',
	templateUrl: './checkout-card.component.html',
	styleUrls: ['./checkout-card.component.scss'],
	animations: [smoothOpenClose],
})
export class CheckoutCardComponent implements OnInit, OnDestroy, OnChanges {
	@Input() enableResubmitOnError = false;
	@Input() errorMessageKey: string;
	@Input() buttonText = 'checkout-card.button';
	@Output() cardTokenized = new EventEmitter<CheckoutResponse>();
	ckoState = {
		'card-number': {
			showDefaultErrorIcon: false,
			showErrorIcon: false,
			showErrorMessage: false,
			inputFocused: false,
		},
		'expiry-date': {
			showDefaultErrorIcon: false,
			showErrorIcon: false,
			showErrorMessage: false,
			inputFocused: false,
		},
		cvv: {
			showDefaultErrorIcon: false,
			showErrorIcon: false,
			showErrorMessage: false,
			inputFocused: false,
		},
	};
	paymentMethod: CkoPaymentMethodType;
	showPaymentMethod = false;
	isCardValid = false;
	isCardSchemaAccepted: boolean;
	cardToken: string;
	submittingCard = false;
	intervalId: any;
	frameReady = false;
	isCardBinAccepted = true;
	partnerKey: string;
	supportedCardBins: string[] = [];

	constructor(
		private appConfig: AppConfig,
		private translateService: TranslateService,
		private changeDetectorRef: ChangeDetectorRef,
		private clientSettingsService: WhiteLabelClientSettingsService,
		private featureFlagsService: FeatureFlagsService,
		private sentryService: SentryService
	) {}

	ngOnInit(): void {
		this.supportedCardBins = this.clientSettingsService.getSettings().supportedCardBins;
		this.partnerKey = this.clientSettingsService.getSettings().key;

		this.intervalId = setInterval(() => {
			if (typeof Frames !== 'undefined') {
				clearInterval(this.intervalId);
				Frames.init({
					publicKey: this.appConfig.checkoutPublicKey,
					acceptedPaymentMethods: ['Visa'],
					localization: this.mapLocaleString(),
					style: {
						base: {
							fontSize: '16px',
						},
					},
				});

				Frames.addEventHandler(Frames.Events.FRAME_ACTIVATED, _ => {
					this.frameReady = true;
					this.changeDetectorRef.detectChanges();
				});
				Frames.addEventHandler(Frames.Events.FRAME_VALIDATION_CHANGED, event => this.onValidationChanged(event));
				Frames.addEventHandler(Frames.Events.FRAME_FOCUS, event => this.onInputFocused(event.element));
				Frames.addEventHandler(Frames.Events.FRAME_BLUR, event => this.onInputBlured(event.element));
				Frames.addEventHandler(Frames.Events.CARD_VALIDATION_CHANGED, () => this.onCardValidationChanged());
				Frames.addEventHandler(
					Frames.Events.CARD_BIN_CHANGED,
					event =>
						this.supportedCardBins.length > 0 &&
						this.featureFlagsService.isFlagEnabled(CARD_BIN_CHECKING) &&
						this.onCardBinChanged(event)
				);
				Frames.addEventHandler(Frames.Events.CARD_TOKENIZATION_FAILED, error => this.onCardTokenizationFailed(error));
				Frames.addEventHandler(Frames.Events.PAYMENT_METHOD_CHANGED, event => this.onPaymentMethodChanged(event));

				this.changeDetectorRef.detectChanges();
			}
		}, 300);
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.enableResubmitOnError && changes.enableResubmitOnError.currentValue) {
			Frames.enableSubmitForm();
		}
		this.changeDetectorRef.detectChanges();
	}

	submitCard() {
		this.submittingCard = true;

		from(Frames.submitCard()).subscribe(
			(payload: CheckoutResponse) => {
				//send this token to new endpoint
				this.cardToken = payload.token;
				this.cardTokenized.emit(payload);
			},
			error => {
				this.sentryService.addHttpResponseMessage(error.message);
				this.sentryService.captureMessage('Error submitting card', 'error');
			},
			() => {
				this.submittingCard = false;
			}
		);
	}

	onCardBinChanged(event) {
		const e: CkoElementType = 'card-number';

		if (!this.supportedCardBins.some(card => event.bin.startsWith(card))) {
			this.isCardBinAccepted = false;
			this.clearPaymentMethodIcon();
			this.setDefaultErrorIcon(e);
			this.setErrorIcon(e);
			this.setErrorMessage(e);
		} else {
			this.isCardBinAccepted = true;
			this.showPaymentMethodIcon();
			this.setDefaultIcon(e);
			this.clearErrorIcon(e);
			this.clearErrorMessage(e);
		}
		this.changeDetectorRef.detectChanges();
	}

	onValidationChanged(event) {
		const e: CkoElementType = event.element;

		if (event.isValid || event.isEmpty) {
			if (e === 'card-number' && !event.isEmpty) {
				this.showPaymentMethodIcon();
				this.errorMessageKey = null;
			}
			this.setDefaultIcon(e);
			this.clearErrorIcon(e);
			this.clearErrorMessage(e);
		} else {
			if (e === 'card-number') {
				this.clearPaymentMethodIcon();
			}
			this.setDefaultErrorIcon(e);
			this.setErrorIcon(e);
			this.setErrorMessage(e);
		}
		this.changeDetectorRef.detectChanges();
	}

	onCardValidationChanged() {
		this.isCardValid = Frames.isCardValid();
		this.changeDetectorRef.detectChanges();
	}

	onCardTokenizationFailed(error) {
		//console.log('CARD_TOKENIZATION_FAILED: %o', error);
		Frames.enableSubmitForm();
		this.changeDetectorRef.detectChanges();
	}

	onPaymentMethodChanged(event) {
		//check if card scheme is accepted payment method (Visa card in this case)
		this.isCardSchemaAccepted = event.isPaymentMethodAccepted;

		const pm: CkoPaymentMethodType = event.paymentMethod;

		if (!pm) {
			this.clearPaymentMethodIcon();
		} else {
			this.clearErrorIcon('card-number');
			this.showPaymentMethodIcon(pm);
		}
		this.changeDetectorRef.detectChanges();
	}

	showPaymentMethodIcon(pm?: CkoPaymentMethodType) {
		this.showPaymentMethod = true;
		if (pm) {
			this.paymentMethod = pm;
		}
	}

	clearPaymentMethodIcon() {
		this.showPaymentMethod = false;
	}

	setDefaultIcon(el: CkoElementType) {
		this.ckoState[el].showDefaultErrorIcon = false;
	}

	setDefaultErrorIcon(el: CkoElementType) {
		this.ckoState[el].showDefaultErrorIcon = true;
	}

	setErrorIcon(el: CkoElementType) {
		this.ckoState[el].showErrorIcon = true;
	}

	clearErrorIcon(el: CkoElementType) {
		this.ckoState[el].showErrorIcon = false;
	}

	setErrorMessage(el: CkoElementType) {
		this.ckoState[el].showErrorMessage = true;
	}

	clearErrorMessage(el: CkoElementType) {
		this.ckoState[el].showErrorMessage = false;
	}

	onInputFocused(el: CkoElementType) {
		this.ckoState[el].inputFocused = true;
		this.changeDetectorRef.detectChanges();
	}

	onInputBlured(el: CkoElementType) {
		this.ckoState[el].inputFocused = false;
		this.changeDetectorRef.detectChanges();
	}

	showInputError(el: CkoElementType): boolean {
		return !this.ckoState[el].inputFocused && this.ckoState[el].showErrorMessage;
	}

	get showCardSchemaError(): boolean {
		return (
			!this.ckoState['card-number'].inputFocused &&
			!this.ckoState['card-number'].showErrorMessage &&
			this.isCardSchemaAccepted === false
		);
	}

	get showCardBinError(): boolean {
		return (
			!this.ckoState['card-number'].inputFocused &&
			!this.ckoState['card-number'].showErrorMessage &&
			this.isCardBinAccepted === false
		);
	}

	mapLocaleString(): string {
		// https://www.checkout.com/docs/integrate/frames/frames-customization-guide#Localization
		const locale = this.translateService.currentLang;
		const localeMap = {
			da: 'DA-DK',
			sv: 'SV-SE',
			en: 'EN-GB',
			nb: 'NB-NO',
			fi: 'FI-FI',
			de: 'DE-DE',
		};
		return localeMap[locale] ?? localeMap['sv'];
	}

	ngOnDestroy() {
		clearInterval(this.intervalId);
	}
}
