<label class="flex items-center cursor-pointer">
	<input
		type="radio"
		[checked]="isChecked()"
		class="w-5 h-5 transition-colors duration-300 border border-medium-grey outline-none checked form-radio text-radio-500 focus:ring-2"
		[ngClass]="{
		'border-medium-grey focus:border-medium-grey focus:ring-radio-100' : !hasErrors(),
		'border-system-red' : hasErrors() }"
		[disabled]="disabled"
		[value]="value"
		(change)="onChange()"
		(blur)="onTouched()" />
	<span class="ml-2 font-medium leading-none text-almost-black">
		<ng-content></ng-content>
	</span>
</label>
