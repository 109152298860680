import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ContractType, SharedFileDto, SharedFileHeaderDto, SharedFilesHttpService } from 'app/api';
import { OrganizationService, SentryService, ToastService } from 'app/services';

@Component({
	selector: 'app-contracts',
	templateUrl: './contracts.component.html',
	styleUrls: ['./contracts.component.scss'],
})
export class ContractsComponent implements OnInit {
	@Input() contracts: SharedFileDto[];
	ContractType = ContractType;
	contractLoading: Record<string, boolean> = {};

	constructor(
		private translateService: TranslateService,
		private organizationService: OrganizationService,
		private sharedFilesService: SharedFilesHttpService,
		private toastService: ToastService,
		private sentryService: SentryService
	) {}
	ngOnInit(): void {}

	isContractLoading(id) {
		return this.contractLoading[id];
	}

	getContract(fileId) {
		if (this.contractLoading[fileId]) {
			return;
		}
		this.contractLoading[fileId] = true;
		const organizationId = this.organizationService.getOrganizationId();
		const creditId = this.organizationService.getCreditId();
		this.sharedFilesService.getSharedFileForOrganizationAndCredit(organizationId, creditId, fileId).subscribe(
			file => {
				this.contractLoading[fileId] = false;
				this.sentryService.addBreadcrumb('Contract URL fetched', 'contracts', {
					fileUrl: file.url,
				});
				this.sentryService.captureMessage('Download contract triggered');
				document.location.href = file.url;
			},
			error => {
				this.sentryService.addHttpResponseMessage(error.message);
				this.sentryService.captureMessage('Download contract error triggered', 'error');
				this.toastService.error('home.toast.download-contract.error');
				this.contractLoading[fileId] = false;
			}
		);
	}

	getContractTitle(contract: SharedFileHeaderDto): string {
		return (
			contract.properties?.ContractTitle ||
			contract.name ||
			this.translateService.instant('home.contracts.type.placeholder')
		);
	}
}
