import { SignedStatus, WithdrawalRequestDto } from 'app/api';
import { WithdrawalRequestStep } from 'app/models/withdrawalRequestStep';

function pending(request: WithdrawalRequestDto): boolean {
	return ['awaitingContractSigned', 'creditAssessmentComplete'].includes(request.status);
}

export function contractsCreated(request: WithdrawalRequestDto): boolean {
	return request.signatories.length > 0;
}

export function allSignatoriesSigned(request: WithdrawalRequestDto): boolean {
	return request?.signatories.reduce(
		(signedSoFar, signatory) => signedSoFar && signatory.signed_status === SignedStatus.Signed,
		true
	);
}

export function canCancel(request: WithdrawalRequestDto) {
	return request !== null && pending(request) && !request.countersigned;
}

export function processStep(
	request: WithdrawalRequestDto,
	shouldSignContractAfterAssessment = false
): WithdrawalRequestStep {
	if (request.status === 'awaitingContractSigned') {
		if (!contractsCreated(request) && shouldSignContractAfterAssessment) {
			return WithdrawalRequestStep.CreditAssessmentComplete;
		}
		return WithdrawalRequestStep.AwaitingContractsSigned;
	} else if (request.status === 'creditAssessmentComplete') {
		if (!contractsCreated(request)) {
			return WithdrawalRequestStep.CreditAssessmentComplete;
		} else if (allSignatoriesSigned(request) && request.countersigned && request.final_decision === 'Approved')
			return WithdrawalRequestStep.AwaitingPayout;
		else return WithdrawalRequestStep.AwaitingContractsSigned;
	} else {
		return null;
	}
}

export function requestApproved(request: WithdrawalRequestDto) {
	return request.final_decision === 'Approved';
}

export function getStepPosition(
	step: WithdrawalRequestStep,
	shouldSignContractAfterAssessment = false
): number {
	let position = 0;
	if (!shouldSignContractAfterAssessment) {
		switch (step) {
			case WithdrawalRequestStep.AwaitingContractsSigned:
				position = 1;
				break;
			case WithdrawalRequestStep.CreditAssessmentComplete:
				position = 2;
				break;
			case WithdrawalRequestStep.AwaitingPayout:
				position = 3;
				break;
		}
	} else {
		switch (step) {
			case WithdrawalRequestStep.CreditAssessmentComplete:
				position = 1;
				break;
			case WithdrawalRequestStep.AwaitingContractsSigned:
				position = 2;
				break;
			case WithdrawalRequestStep.AwaitingPayout:
				position = 3;
				break;
		}
	}
	return position;
}
