<label class="flex items-start mb-4">
	<input
		type="checkbox"
		class="w-5 h-5 transition-colors duration-300 border rounded cursor-pointer form-checkbox focus:ring-2"
		[ngClass]="{
			'text-checkbox-500' : !disabled,
			'text-checkbox-300' : disabled,
			'border-button-color focus:border-checkbox-500 focus:ring-checkbox-100' : !hasErrors(),
			'border-system-red' : hasErrors() }"
		[ngModel]="value"
		(ngModelChange)="onChange($event)"
		(blur)="onTouched()"
		[disabled]="disabled" />
	<span class="ml-2 font-medium leading-tight text-almost-black" [ngClass]="{ 'cursor-pointer' : !disabled }">
		<ng-content select="[text]"></ng-content>
	</span>
</label>
<div *ngIf="hasErrors() && control.errors?.required" class="flex mb-2 -mt-3 leading-tight text-system-red">
	<app-svg-icon [iconName]="'arrow-up-left.svg'" [iconId]="'arrow-up-left'"></app-svg-icon>
	<div class="mt-2 ml-2 text-sm leading-none"><ng-content select="[error]"></ng-content></div>
</div>
