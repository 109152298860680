<div class="w-full" [class.pb-8]="!hasErrors()">
	<div class="pb-2">
		<label class="{{labelSize}} font-bold {{labelColor}} text-almost-black  leading-6 pointer-events-none" for="{{label}}">{{ label }}</label>
	</div>
	<div class="relative h-full custom-select">
		<select
			[ngModel]="value"
			(ngModelChange)="onChange($event)"
			(blur)="onTouched()"
			[required]="required"
			[ngModelOptions]="{standalone: true}"
			class="block w-full px-14.5 py-14.5 text-base font-medium leading-normal bg-white rounded-md outline-none appearance-none cursor-pointer border-3/2 text-almost-black focus:ring-0"
			[ngClass]="{
				'border-almost-black': !hasErrors(),
				'border-system-red' : hasErrors(),
				'focus:border-system-blue' : !hasErrors(),
				'focus:border-system-red' : hasErrors() }">
			<option value="" selected hidden>{{ placeholder  || 'select.placeholder' | translate}}</option>
			<option [ngValue]="option" *ngFor="let option of options">{{ (option.text || option.label) | translate }}</option>
		</select>
		<app-svg-icon
			*ngIf="hasErrors() && control.errors?.required"
			class="absolute bottom-0 items-center py-14.5 pr-3 right-12 text-system-red"
			[iconName]="'alert.svg'"
			[iconId]="'alert'"></app-svg-icon>
	</div>
	<div *ngIf="hasErrors() && control.errors?.required" class="flex justify-end pt-2 pr-4 text-sm text-system-red">
		{{ 'control.error.required' | translate }}
	</div>
</div>
