<div [ngClass]="bgColor">
	<div
		class="flex items-center justify-between py-3 border rounded-box"
		*ngFor="let signatory of signatories; let i = index"
		[ngClass]="hideBorder ? 'border-none' : 'border-light-grey'">
		<div class="flex flex-col items-start">
			<div [ngClass]="titleTextColor">{{signatory.first_name}} {{signatory.last_name}}</div>
			<div class="text-sm font-medium" [ngClass]="subTextColor">{{signatory.phone}}</div>
		</div>
		<ng-container *ngIf="!usesSignicatSigning">
			<a
				*ngIf="!signatory.signed && signatory.is_logged_in_user"
				class="text-sm underline hover:opacity-75"
				[href]="signatory.signing_url"
				[ngClass]="{'text-system-blue': !whiteLinkColor, 'text-medium-grey': whiteLinkColor }"
				target="_blank"
				translate="wrstatus.signatory.sign"></a>
			<a
				*ngIf="!signatory.signed && !signatory.is_logged_in_user"
				(click)="resendSMS(signatory)"
				class="text-sm underline hover:opacity-75"
				[ngClass]="{'pointer-events-none opacity-70' : smsSent.has(signatory.id), 'text-system-blue': !whiteLinkColor, 'text-medium-grey': whiteLinkColor }"
				translate="wrstatus.signatory.resend"></a>
		</ng-container>
		<ng-container *ngIf="usesSignicatSigning">
			<a *ngIf="!signatory.signed" (click)="createContractLink()" class="text-sm underline text-system-blue hover:opacity-75" translate="wrstatus.signatory.sign"></a>
		</ng-container>

		<app-svg-icon *ngIf="signatory.signed" class="text-system-green" [iconName]="'check-circle-bg.svg'" [iconId]="'check-circle-bg'"></app-svg-icon>
	</div>
</div>
