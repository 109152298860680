<div class="w-full max-w-lg mb-4">
	<div class="relative">
		<label class="text-sm font-bold leading-7 transition-colors duration-300 pointer-events-none" for="{{label}}">{{ label }}</label>
		<textarea
			class="block w-full px-3 py-2 text-base font-medium leading-normal text-black transition duration-300 rounded-md appearance-none focus:ring-0 border-3/2 cursor-text"
			[ngClass]="{
                'border-almost-black focus:border-system-blue' : !hasErrors() && !readonly && !disabled,
                'border-system-red' : hasErrors() && !readonly && !disabled }"
			[id]="name"
			[name]="name"
			[placeholder]="placeholder"
			[required]="required"
			[disabled]="disabled"
			[readonly]="readonly"
			[rows]="rows"
			[ngModel]="value"
			(ngModelChange)="onChange($event)"
			(blur)="onTouched()"
			[ngModelOptions]="{standalone: true}"
			[pattern]="pattern"></textarea>
	</div>
	<div *ngIf="hasErrors()">
		<div *ngFor="let error of control.errors | keyvalue">
			<div class="mb-2 text-sm font-semibold leading-normal text-system-red">
				{{
				error.key === 'distinct' ? ('control.error.' + error.key + '.' + name | translate) :
				error.key === 'pattern' ? ('control.error.pattern' | translate : { name: label}) :
				('control.error.' + error.key | translate) }}
			</div>
		</div>
	</div>
</div>
