<div>
	<h2 *ngIf="showTitle" class="pt-6 pb-4" translate="inline-faq.title"></h2>
	<div class="rounded-2xl border border-black/[.06]">
		<ng-container *ngFor="let key of keys; let i = index">
			<div *ngIf="i < 3" (click)="toggle(key, i)">
				<app-faq-item
					[key]="key"
					[question]="key + '.title' | translate | simpleMarkdown : { '*' : 'link-underline'}"
					[answer]="key + '.text' | translate | simpleMarkdown : { '*' : 'link-underline'}"></app-faq-item>
			</div>
		</ng-container>
		<div class="flex justify-end w-full px-6 py-4">
			<app-button [iconId]="'chevron-down'" [type]="'small'" (action)="openFaqs.emit()">{{ 'inline-faq.more' | translate }}</app-button>
		</div>
	</div>
</div>
