export const yabieVariables = {
	'font-higlight': 'CentraNo2',
	'font-header': 'CentraNo2',
	'font-body': 'Inter UI',
	'primary-100': '#FDC129',
	'primary-200': '#FDC129',
	'primary-400': '#ffcb33',
	'primary-500': '#ffcb33',
	'primary-700': '#e99b15',
	'secondary-100': 'rgba(113, 230, 175, 0.6)',
	'secondary-200': '#71E6AF',
	'secondary-400': '#71E6AF',
	'secondary-500': 'rgba(113, 230, 175, 0.6)',
	'secondary-900': '#71E6AF',

	'checkbox-100': '#E6E8E8',
	'checkbox-300': '#AAA',
	'checkbox-500': '#71E6AF',
	'radio-100': '#E6E8E8',
	'radio-500': '#333333',

	'rounded-box': '16px',
	'rounded-notification': '0px',

	'min-width': 'fit-content',

	'highlight-light': 'rgba(113, 230, 175, 0.6)',
	'highlight-border': '#1E1E1E',

	'slider-color': '#71E6AF',
	'slider-border-color': '#1B1B1B',

	//Box card styles
	'box-background': '#FECD59',
	'box-info': '#000000',
	'box-border': '#1E1E1E',

	//Box card styles - secondary
	'box-background-light': '#E2E2E2',
	'box-info-light': '#000000',
	'box-border-light': '#FFFFFF',

	separator: '#AAA',

	'input-phone-prefix': '#E4E4E4',
	spinner: '#ABABAB',

	highlighted: '#1B1B1B',
	title: '#000000',
	paragraph: '#474747',

	dark: '#000000',
	brand: '#71E6AF',

	'footer-background': 'rgba(113, 230, 175, 0.6)',

	'common-padding': '16px',
	'common-margin': '16px',
	'common-gap': '32px',

	// BUTTON STYLES

	// primary button
	'button-color': '#71E6AF',
	'button-color-hover': 'rgba(113, 230, 175, 0.6)',
	'button-text': '#000000',
	'button-text-hover': '#000000',
	'rounded-button': '24px',
	'padding-button': '12px 24px',
	'border-button-color': '#71E6AF',

	// secondary button
	'button-color-secondary': 'transparent',
	'button-text-secondary': '#000000',
	'button-text-secondary-hover': '#000000',
	'rounded-button-secondary': '24px',
	'padding-button-secondary-outlined': '10px 24px',
	'button-border-secondary-outlined': '#71E6AF',
	'button-border-secondary-hover': '#71E6AF',
	// --------------------------------------- //
};
