import { formatNumber } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Optional, Output, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

const MINUS_UNICODE = 8722;
const HYPEN_MINUS_UNICODE = 45;
@Component({
	selector: 'app-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit, ControlValueAccessor {
	@Input() label: string;
	@Input() labelColor = 'text-almost-black';
	@Input() labelSize = 'text-base';
	@Input() name: string;
	@Input() prefix: string;
	@Input() suffix: string;
	@Input() paddingLeft = 'pl-4';
	@Input() helperText = '';
	@Input() type = 'text';
	@Input() autocomplete = 'on';
	@Input() value;
	@Input() placeholder: string;
	@Input() required = false;
	@Input() readonly = false;
	@Input() pattern: string;
	@Input() bgColor = 'bg-white';
	@Input() hasValidIndicator?: boolean = false;
	@Input() validationMessage?: string = 'onboarding.offer.company.limited-company';
	@Input() prefixIcon: string;
	@Input() prefixIconId: string;
	@Output() handleOnFocus = new EventEmitter<void>();
	@Output() handleOnBlur = new EventEmitter<void>();
	@Output() onButtonClick = new EventEmitter<void>();
	@Output() helperTextClick = new EventEmitter<void>();
	isFocused = false;

	onTouched = () => {};
	onChanged = (_: any) => {};

	constructor(@Self() @Optional() public control: NgControl) {
		this.control.valueAccessor = this;
	}

	ngOnInit(): void {}

	writeValue(value: any): void {
		if (this.type === 'number') {
			this.value = this.showNumericInput(value?.toString());
		} else {
			this.value = value;
		}
	}

	registerOnChange(fn: any): void {
		this.onChanged = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	onBlur() {
		this.isFocused = false;
		this.onTouched();
		this.handleOnBlur.emit();
	}

	onFocus() {
		this.isFocused = true;
		this.handleOnFocus.emit();
	}

	hasErrors() {
		return (this.control.dirty || this.control.touched) && this.control.invalid;
	}

	get errorMessage() {
		return Object.keys(this.control.errors)[0];
	}

	onChange(value) {
		if (this.type === 'number') {
			this.value = this.showNumericInput(value.toString());
			this.emitNumber(value);
		} else {
			this.value = value;
			this.onChanged(this.value);
		}
	}

	showNumericInput(value: string): number | string {
		const isNumericValue = !!value?.replace(/\D/g, '');
		const isMinusSign =
			value?.length === 1 && (value?.charCodeAt(0) == MINUS_UNICODE || value?.charCodeAt(0) == HYPEN_MINUS_UNICODE);
		if (isNumericValue) {
			//show formatted number in template
			return this.handleInputFormat(value);
		} else if (isMinusSign) {
			//show minus sign
			return value;
		} else {
			//anything else is not a valid input, show empty field
			return '';
		}
	}

	emitNumber(value: string) {
		//send value without spaces in onChanged
		const isNumericValue = !!value.replace(/\D/g, '');
		const isMinusSign =
			value.length === 1 && (value?.charCodeAt(0) == MINUS_UNICODE || value?.charCodeAt(0) == HYPEN_MINUS_UNICODE);
		if (isNumericValue) {
			this.onChanged(this.parseNumber(value) || '');
		} else if (isMinusSign) {
			this.onChanged(value);
		} else {
			this.onChanged('');
		}
	}

	private handleInputFormat(value: string): string {
		//locale code for locale format rules to use
		//english locale uses commas instead of empty spaces,
		//so we should hardcode it to 'sv' if we always want spaces
		const locale = 'sv';
		const formattedNumber = formatNumber(this.parseNumber(value), locale);
		return formattedNumber;
	}

	private parseNumber(value: string): number {
		const negativeNumber = value.charCodeAt(0) == MINUS_UNICODE || value.charCodeAt(0) == HYPEN_MINUS_UNICODE;
		const sign = negativeNumber ? -1 : 1;
		if (negativeNumber) value = value.substring(1);
		const parsedNumber = +value.replace(/\D/g, '');
		return sign * Math.abs(parsedNumber);
	}

	preventNonNumeric(event) {
		if (this.type === 'number') {
			const allowedRegex = /[-0-9]/g;

			if (!event?.key.match(allowedRegex)) {
				event.preventDefault();
			}
		}
	}
}
