import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { smoothHeight } from 'app/animations/smooth.animations';
import { OrganizationDto } from 'app/api';
import { InitResponseDto, SessionsScrapingService } from 'app/api-scraping';
import { AnalyticsService } from 'app/services/analytics.service';
import { BaseUrlService } from 'app/services/base-url.service';
import { DeviceService } from 'app/services/device.service';
import { Observable, Subject, throwError, timer } from 'rxjs';
import {
	catchError,
	distinctUntilChanged,
	exhaustMap,
	filter,
	map,
	publishReplay,
	refCount,
	retry,
	skip,
	take,
	takeUntil,
} from 'rxjs/operators';

@Component({
	selector: 'bankid-scrape-modal',
	templateUrl: './bankid-scrape-modal.component.html',
	styleUrls: ['./bankid-scrape-modal.component.scss'],
	animations: [smoothHeight],
})
export class BankIDScrapeModalComponent implements OnInit, OnDestroy {
	@Input() visible = false;
	@Input() bankSessionId: string;
	@Input() redirectEnabled: boolean;
	@Output() close = new EventEmitter<void>();
	@Output() failed = new EventEmitter<string>();
	@Output() completed = new EventEmitter<void>();
	@Output() retry = new EventEmitter<void>();
	@Output() next = new EventEmitter<void>();
	private readonly destroy$ = new Subject<string>();
	qrUrl = '';
	scrapingSession$: Observable<InitResponseDto>;
	availableOrganizations$: Observable<OrganizationDto[]>;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private analyticsService: AnalyticsService,
		private sessionsScrapingService: SessionsScrapingService,
		private deviceService: DeviceService,
		private baseUrlService: BaseUrlService
	) {}

	ngOnInit() {
		this.checkSessionScrapingStatus();
	}

	private checkSessionScrapingStatus() {
		this.scrapingSession$ = timer(0, 500).pipe(
			exhaustMap(_ =>
				this.sessionsScrapingService.sessionsIdStatusGet(this.bankSessionId).pipe(catchError(err => throwError(err)))
			),
			retry(10),
			catchError(err => throwError(err)),
			map(session => {
				let hint_code = (session as any).hint_code;
				if (hint_code === 'OPEN_BANKDID' && this.deviceService.isOtherDevice()) hint_code = 'SCAN_QR';
				return { ...session, hint_code };
			}),
			publishReplay(1),
			refCount()
		);

		this.availableOrganizations$ = this.scrapingSession$.pipe(
			filter(session => session.status === 'SELECT_COMPANY'),
			map(session => (session as any).available_organizations),
			take(1),
			takeUntil(this.destroy$)
		);

		this.scrapingSession$
			.pipe(
				filter(session => (session as any).hint_code === 'SCAN_QR'),
				distinctUntilChanged(),
				takeUntil(this.destroy$)
			)
			.subscribe(session => this.showQRCode(session.auto_start_token, session.qr_start_token));

		this.scrapingSession$
			.pipe(
				filter(session => session.status === 'COMPLETE'),
				skip(3),
				take(1),
				takeUntil(this.destroy$)
			)
			.subscribe(() => {
				this.trackGAEvent('bankID-success');
				this.completed.emit();
			});

		this.scrapingSession$
			.pipe(
				filter(session => session.status === 'COMPLETE'),
				take(1),
				takeUntil(this.destroy$)
			)
			.subscribe(() => {
				this.trackGAEvent('bankID-success');
				//same event as complete, but without the success message in UI
				//needed when scraping is not the last step, when next modal needs to open
				this.next.emit();
			});

		this.scrapingSession$
			.pipe(
				filter(session => session.status === 'FAILED'),
				skip(1),
				take(1),
				takeUntil(this.destroy$)
			)
			.subscribe(session => {
				this.failed.emit(session.hint_code);
				this.trackGAEvent('bankID-failed', session.hint_code);
			});

		this.scrapingSession$
			.pipe(
				filter(session => session.hint_code === 'OPEN_BANKDID'),
				take(1),
				takeUntil(this.destroy$)
			)
			.subscribe(session => this.trackGAEvent('bankID-open', session.hint_code));

		this.scrapingSession$
			.pipe(
				filter(session => (session as any).hint_code === 'SCAN_QR'),
				take(1),
				takeUntil(this.destroy$)
			)
			.subscribe(session => this.trackGAEvent('bankID-open', session.hint_code));

		this.scrapingSession$
			.pipe(
				filter(session => session.hint_code === 'SIGN'),
				take(1),
				takeUntil(this.destroy$)
			)
			.subscribe(() => this.trackGAEvent('bankID-sign'));
	}

	companySelected(identifier) {
		this.sessionsScrapingService.sessionsIdCompaniesPut(this.bankSessionId, { identifier }).subscribe();
	}

	openBankIDApp() {
		this.scrapingSession$.pipe(take(1)).subscribe(session => {
			const returnUrl =
				this.baseUrlService.getClientBaseUrl() +
				this.router.url +
				this.getQuerySeparator() +
				'bank_session_id=' +
				this.bankSessionId;

			// let url = 'https://app.bankid.com/';
			// url += '?autostarttoken=' + session.auto_start_token;
			let url = session.auto_start_token_url;

			if (this.redirectEnabled) url += '&redirect=' + encodeURIComponent(returnUrl);
			else url += '&redirect=';

			console.log('LAUNCH URL', url);
			document.location.href = url;
		});
	}

	closeModal() {
		this.sessionsScrapingService.sessionsIdDelete(this.bankSessionId).subscribe(() => {
			this.trackGAEvent('bankID-cancel');
			this.close.emit();
		});
	}

	retryBankID() {
		this.sessionsScrapingService.sessionsIdDelete(this.bankSessionId).subscribe(() => {
			this.trackGAEvent('bankID-retry');
			this.retry.emit();
		});
	}

	switchDevice() {
		this.sessionsScrapingService.sessionsIdDelete(this.bankSessionId).subscribe(() => {
			this.trackGAEvent('bankID-switch');
			this.deviceService.switchDevice();
			this.retry.emit();
		});
	}

	private showQRCode(autoStartToken, qrStartToken) {
		this.qrUrl = qrStartToken ? qrStartToken : 'bankid:///?autostarttoken=' + autoStartToken + '&redirect=';
	}

	private trackGAEvent(event: string, label?: string) {
		if (label) this.analyticsService.registerEvent('bankID', event, label);
		else this.analyticsService.registerEvent('bankID', event);
	}

	private getQuerySeparator(): string {
		let querySeparator = '&';
		if (Object.keys(this.route.snapshot.queryParams).length === 0) querySeparator = '?';
		return querySeparator;
	}

	ngOnDestroy() {
		this.destroy$.next(undefined);
		this.destroy$.complete();
	}
}
