export const frodaVariables = {
	'font-higlight': 'SuisseIntl',
	'font-header': 'SuisseIntl',
	'font-body': 'SuisseIntl',
	'primary-100': '#EFE7FF',
	'primary-200': '#C6ABFF',
	'primary-400': '#9469F0',
	'primary-500': '#774DD0',
	'primary-700': '#310B49',
	'secondary-100': '#EFE7FF',
	'secondary-200': '#C6ABFF',
	'secondary-400': '#9469F0',
	'secondary-500': '#774DD0',
	'secondary-900': '#262133',

	'checkbox-100': '#EFE7FF',
	'checkbox-300': '#C6ABFF',
	'checkbox-500': '#774DD0',
	'radio-100': '#EFE7FF',
	'radio-500': '#774DD0',

	'rounded-box': '1rem',
	'rounded-notification': '0px',
	'min-width': 'fit-content',
	'highlight-light': '#FBF2EA',
	'highlight-dark': '',
	'slider-color': '#FFFFFF',
	'slider-border-color': '#9469F0',

	'input-phone-prefix': '#E3E3E3',
	spinner: '#B4A0D8',

	highlighted: '#774DD0',
	title: '#1a1a1a',
	paragraph: 'rgb(0 0 0 / .6)',

	dark: '#1E1E1E',
	brand: '#FFFFFF',

	'footer-background': '#E3F5F1',

	'common-padding': '16px',
	'common-margin': '16px',
	'common-gap': '32px',

	// BUTTON STYLES

	// primary button
	'button-color': '#9469F0',
	'button-color-hover': '#774DD0',
	'button-text': '#FFFFFF',
	'button-text-hover': '#FFFFFF',
	'rounded-button': '8px',
	'padding-button': '12px 32px',
	'border-button-color': '#9469F0',

	// secondary button
	'button-color-secondary': 'transparent',
	'button-text-secondary': '#9469F0',
	'button-text-secondary-hover': '#774DD0',
	'rounded-button-secondary': '8px',
	'padding-button-secondary-outlined': '10px 32px',
	'button-border-secondary-outlined': '#9469F0',
	'button-border-secondary-hover': '#9469F0',
	// ----------------END BUTTON STYLES----------------------- //
};
