import { Component, OnInit, Input, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
	selector: 'app-radio-button',
	templateUrl: './radio-button.component.html',
	styleUrls: ['./radio-button.component.scss'],
})
export class RadioButtonComponent implements OnInit, ControlValueAccessor {
	@Input() value;
	@Input() disabled = false;
	model: any;

	onTouched = () => {};
	onChanged = (_: any) => {};

	constructor(@Self() @Optional() public control: NgControl) {
		this.control.valueAccessor = this;
	}

	ngOnInit() {
		this.control.control.valueChanges.subscribe(value => {
			if (this.model === value) return;
			this.writeValue(value);
		});
	}

	writeValue(value: any): void {
		this.model = value;
	}

	registerOnChange(fn: any): void {
		this.onChanged = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	onChange() {
		this.model = this.value;
		this.onChanged(this.model);
	}

	hasErrors() {
		return (this.control.dirty || this.control.touched) && this.control.errors;
	}

	isChecked() {
		return JSON.stringify(this.model) === JSON.stringify(this.value);
	}
}
