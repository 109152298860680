/**
 * Froda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PaymentIntervalType = 'daily' | 'bankday' | 'weekly' | 'monthly' | 'custom' | 'weeklySimple';

export const PaymentIntervalType = {
    Daily: 'daily' as PaymentIntervalType,
    Bankday: 'bankday' as PaymentIntervalType,
    Weekly: 'weekly' as PaymentIntervalType,
    Monthly: 'monthly' as PaymentIntervalType,
    Custom: 'custom' as PaymentIntervalType,
    WeeklySimple: 'weeklySimple' as PaymentIntervalType
};

