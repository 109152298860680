import { Component, Input, OnInit } from '@angular/core';
import { expandRows, fadeInOut, smoothOpenClose } from 'app/animations/smooth.animations';
import { SharedFileHeaderDto, SharedFilesHttpService } from 'app/api';
import { OrganizationService } from 'app/services';

@Component({
	selector: 'app-report-item',
	templateUrl: './report-item.component.html',
	styleUrls: ['./report-item.component.scss'],
	animations: [smoothOpenClose, expandRows, fadeInOut],
})
export class ReportItemComponent implements OnInit {
	@Input() report: SharedFileHeaderDto;

	constructor(private sharedFilesService: SharedFilesHttpService, private organizationService: OrganizationService) {}

	ngOnInit(): void {}

	getReport(event, fileId) {
		event.stopPropagation();
		const organizationId = this.organizationService.getOrganizationId();
		const creditId = this.organizationService.getCreditId();
		this.sharedFilesService
			.getSharedFileForOrganizationAndCredit(organizationId, creditId, fileId)
			.subscribe(file => (document.location.href = file.url));
	}
}
