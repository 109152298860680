<div class="flex flex-col w-full gap-6">
	<form [formGroup]="selectSNIForm" class="flex w-full flex-col gap-2">
		<ng-container *ngFor="let option of defaultSNIOptions; let i = index">
			<app-radio-select-item class="flex w-full" [value]="option" formControlName="selectedSNI">
				<span class="font-bold">{{ option.translationKey | translate }}</span>
			</app-radio-select-item>
		</ng-container>
	</form>
	<app-button [type]="'small'" (action)="openMoreSNIOptions()" [iconId]="'plus-icon'" class="flex w-fit">
		{{ 'sni-selector.find-more' | translate }}
	</app-button>
	<app-sni-options-modal [visible]="moreOptionsVisible" [sniOptions]="moreSNIOptions" (closeModal)="closeMoreSNIOptions()" (addNewSniOption)="addNewSniOption($event)" />
</div>

