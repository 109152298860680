<div class="flex flex-col">
	<div *ngFor="let step of withdrawalRequestSteps; let i = index">
		<app-status-step [title]="step.title" [step]="i + 1" [currentStep]="currentStepPosition" [isLastStep]="i === withdrawalRequestSteps.length - 1" [bgColor]="bgColor">
			<div *ngIf="step.content" class="text-sm">{{ step.content | translate }}</div>
			<app-signatory-list
				*ngIf="step.showSignators"
				[pendingRequestId]="pendingRequest?.id"
				[signatories]="pendingRequest?.signatories"
				[titleTextColor]="signatoryTitleTextColor"
				[subTextColor]="signatorySubTextColor"></app-signatory-list>
			<div *ngIf="step.showSignators && waitingForContractsCreated" class="flex flex-col items-center justify-center w-full h-full p-4 mt-4 ">
				<app-loader></app-loader>
			</div>
		</app-status-step>
	</div>
</div>
