import { Injectable } from '@angular/core';
import { LoggingHttpService } from 'app/api';
import { AnalyticsService } from './analytics.service';
import { OrganizationService } from './organization.service';
import { UserService } from './user.service';

@Injectable()
export class WhiteLabelTrackingService {
	constructor(
		private organizationService: OrganizationService,
		private userService: UserService,
		private analyticsService: AnalyticsService,
		private loggingService: LoggingHttpService
	) {}

	//TODO: Track by partner?

	trackStartApplication() {
		const organizationId = this.organizationService.getOrganizationId();
		const user = this.userService.getUser();
		this.analyticsService.registerEvent('white-label', 'start-application', organizationId);
		this.loggingService
			.logInteractionEvent({
				organization_id: organizationId,
				user_id: user.id,
				event_type: 'white-label.start-application',
			})
			.subscribe();
	}

	trackFinishApplication() {
		const organizationId = this.organizationService.getOrganizationId();
		const user = this.userService.getUser();
		this.analyticsService.registerEvent('white-label', 'finish-application', organizationId);
		this.loggingService
			.logInteractionEvent({
				organization_id: organizationId,
				user_id: user.id,
				event_type: 'white-label.finish-application',
			})
			.subscribe();
	}
}
