export const PAGES_WITH_AVAILABLE_CREDIT = ['/v2/home', '/v2/new-loan', '/v2/increase'];
export const TOP_UP_PAGE = '/v2/increase';
export const TYPEFORM_ID = 'WTVkVfUf';
export const TYPEFORM_FEATURE_FLAG = 'TypeformSurvey';
export const TRANSLATION_PREFIX = 'home';
export const MOST_COMMON_EMAIL_DOMAINS = ['gmail.com', 'hotmail.com', 'outlook.com', 'yahoo.com', 'hey.com'];
export const DEFAULT_MIN_LOAN_AMOUNT = 5_000;
export const SUPPORT_PHONE_NUMBER = '08-501 311 00';
export const SUPPORT_EMAIL_ADDRESS = 'support@froda.se';
export const INFO_EMAIL_ADDRESS = 'info@froda.se';

export const MONEY_SOURCE_BANKS = [
	{ code: 'marginalen', text: 'Marginalen' },
	{ code: 'aros', text: 'Aros' },
	{ code: 'swedbank', text: 'Swedbank' },
	{ code: 'seb', text: 'SEB' },
	{ code: 'lansforsakringar', text: 'Länsförsäkringar' },
	{ code: 'handelsbanken', text: 'Handelsbanken' },
	{ code: 'nordea', text: 'Nordea' },
	{ code: 'qred', text: 'Qred' },
	{ code: 'other', text: 'home.final-payment.money-source.other-bank' },
];

export const MONEY_SOURCE_MORE_INFO = {
	otherCompany: 'other-company',
	anotherLoan: 'another-loan',
	other: 'other',
};

export const FRODA_BENEFITS_URL_SE = 'https://froda.se/foretagslan/partnerskap/formaner';

//Unleash feature flags
export const GENERAL_ERROR = 'GeneralError';
export const MF_HIDE_BANK_ACCOUNTS = 'MyFrodaHideBankAccounts';
export const MF_HIDE_SELF_SERVICE = 'MyFrodaHideSelfService';
export const MF_HIDE_SIGNED_CONTRACTS = 'MyFrodaHideSignedContracts';
export const MF_HIDE_EVENTS = 'MyFrodaHideEvents';
export const MF_HIDE_REPORTS = 'MyFrodaHideReports';
export const MF_HIDE_TRANSACTIONS = 'MyFrodaHideTransactions';
export const MF_HIDE_UPDATE_BANK_DATA = 'MyFrodaHideUpdateBankData';
export const MF_HIDE_TOPUPS = 'MyFrodaHideTopups';
export const MF_HIDE_USERS = 'MyFrodaHideUsers';
export const MF_NEW_CREATE_WR_ENDPOINT_FLAG = 'CIA-543';
