<div class="flex flex-col items-center">
	<h1 class="text-xl">{{ 'company-selector.title' | translate }}</h1>
	<form class="mb-6" [formGroup]="form">
		<div class="my-4" *ngFor="let company of companies">
			<app-radio-button [value]="company.identifier || company.org_number" formControlName="companyOrg">
				<span class="text-sm text-almost-black">{{company.name || company.organization_name }}</span>
				<span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-light-grey text-almost-black">
					{{company.registration_number || company.org_number}}
				</span>
			</app-radio-button>
		</div>
	</form>
	<app-button [loading]="loading" class="w-full mb-4 text-center" [disabled]="!selectedIdentifier" (action)="companySelected.emit(selectedIdentifier)">
		{{ 'company-selector.button' | translate }}
	</app-button>
</div>
