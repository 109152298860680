<div class="w-full" [class.pb-8]="!hasErrors() && !helperText && !hasValidIndicator">
	<div class="relative">
		<div class="pb-2">
			<label class="{{labelSize}} font-bold text-almost-black leading-6 {{labelColor}} pointer-events-none" for="{{label}}">{{ label }}</label>
		</div>
		<div
			class="flex flex-row items-center rounded-md border-3/2"
			[ngClass]="{
			'border-almost-black': !(hasErrors() && !readonly),
			'border-system-red' : hasErrors() && !readonly,
			'focus-within:border-system-blue' : !hasErrors() && !readonly,
			'focus-within:border-system-red' : hasErrors() && !readonly}">
			<div
				*ngIf="prefix"
				class="h-full px-14.5 py-14.5 border border-transparent font-medium text-white"
				[ngClass]="{ 'bg-almost-black': !hasErrors(), 'bg-system-red' : hasErrors(), 'bg-system-blue' : !hasErrors() && !readonly && isFocused }">
				{{ prefix }}
			</div>
			<div
				*ngIf="prefixIcon"
				class="h-full px-4 py-14.5 border border-transparent font-medium text-white "
				[ngClass]="{'bg-almost-black': !hasErrors(), 'bg-system-red' : hasErrors(), 'bg-system-blue' : !hasErrors() && !readonly && isFocused }">
				<app-svg-icon [iconName]="prefixIcon" [iconId]="prefixIconId"></app-svg-icon>
			</div>
			<input
				class="block w-full py-14.5 px-14.5 {{bgColor}} {{paddingLeft}} text-base font-medium text-black  outline-none appearance-none cursor-text focus:ring-0 border-none rounded-md"
				[id]="name"
				[name]="name"
				[type]="type !== 'number' ? type : 'text'"
				[autocomplete]="autocomplete"
				[placeholder]="placeholder || 'input.placeholder' | translate"
				[required]="required"
				[readonly]="readonly"
				[ngModel]="value"
				(ngModelChange)="onChange($event)"
				(blur)="onBlur()"
				(focus)="onFocus()"
				[ngModelOptions]="{standalone : true}"
				[pattern]="type === 'number' && !pattern ? '[0-9]*' : pattern"
				(keypress)="preventNonNumeric($event)" />
			<div
				*ngIf="suffix || hasErrors() || hasValidIndicator"
				class="px-14.5 py-14.5 font-medium border-none"
				[ngClass]="{ 'text-system-red' : hasErrors(), 'text-dark-grey': !hasErrors() }">
				<div class="flex">
					<app-svg-icon *ngIf="hasErrors()" class="text-system-red" [iconName]="'alert.svg'" [iconId]="'alert'"></app-svg-icon>
					<app-svg-icon *ngIf="hasValidIndicator && !hasErrors()" class="text-system-green" [iconName]="'check-circle.svg'" [iconId]="'check-circle'"></app-svg-icon>
					<span *ngIf="suffix" class="ml-4">
						<!-- /* TODO: Continue here... suffix part is 2px too high */ -->
						{{ suffix }}
					</span>
				</div>
			</div>
		</div>
	</div>

	<ng-container *ngIf="hasValidIndicator && !hasErrors()">
		<div class="flex justify-end pt-2 pr-4 text-sm text-dark-grey">{{ validationMessage | translate }}</div>
	</ng-container>

	<ng-container *ngIf="hasErrors()">
		<div class="flex justify-end pt-2 pr-4 text-sm text-system-red">
			{{
					errorMessage === 'distinct' ? ('control.error.' + errorMessage + '.' + name | translate) :
					errorMessage === 'pattern' ? ('control.error.pattern' | translate : { name: label}) :
				('control.error.' + errorMessage | translate) }}
		</div>
	</ng-container>
	<ng-container *ngIf="helperText && !hasErrors()">
		<div class="flex justify-end pt-2 pr-4 text-sm text-dark-grey" [innerHtml]="helperText" (click)="helperTextClick.emit()"></div>
	</ng-container>
</div>
